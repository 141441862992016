import Vue from 'vue';
import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import HelloWorld from '@/pages/views/HelloWorld.vue';

Vue.use(VueRouter);

const routes = [
	{
		path: '/signin',
		name: 'Signin',
		component: HelloWorld
	},
	{
		path: '*',
		beforeEnter() {
			window.location = 'https://www.shiftinc.jp/';
		}
	}
];

const isProduction = () => {
	const { port, protocol, hostname } = window.location;

	if (port) return false;
	if (protocol === 'https:') {
		if (hostname.startsWith('dev.') || hostname.indexOf('.dev.') !== -1) return false;
		if (hostname.startsWith('sandbox.') || hostname.indexOf('.sandbox.') !== -1) return false;
		if (hostname.startsWith('green.')) return false;
	}
	return true;
};

const router = new VueRouter({
	mode: 'history',
	routes:
		process.env.NODE_ENV === 'production'
			? routes.filter((route) => !route.developmentOnly)
			: routes
});

Vue.use(
	VueGtag,
	{
		config: { id: isProduction() ? 'G-WTG18RPMQT' : 'UA-DUMY123-1' }
	},
	router
);

export default router;
