import Vue from 'vue';
import VueCookies from 'vue-cookies';

import vuetify from '@/plugins/vuetify';
import customAxios from '@/plugins/custom-axios';
import i18n from '@/plugins/i18n';
import router from '@/pages/router';

import App from './App.vue';

Vue.config.productionTip = false;
Vue.prototype.$axios = customAxios();

Vue.use(VueCookies);

new Vue({
	router,
	vuetify,
	i18n,
	render: (h) => h(App)
}).$mount('#app');
